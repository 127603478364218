import { FixedSkeleton } from "@components";
import clsx from "clsx";
import { FC, PropsWithChildren, useMemo, type JSX } from "react";

import { Typography } from "../typography";

/** 标题 */
const PageContainerTitle: FC<PropsWithChildren> = ({ children }) => (
  <Typography.H4 className="text-nowrap">{children}</Typography.H4>
);

type PageContainerProps = {
  extra?: React.ReactNode;
  loading?: boolean;
  title?: string;
  type?: "card" | "empty"; // default is card type
};
type PageContainerType = { Title: typeof PageContainerTitle } & FC<
  PropsWithChildren<JSX.IntrinsicElements["div"] & PageContainerProps>
>;

/** 页面容器 */
export const PageContainer: PageContainerType = (params) => {
  const {
    children,
    className,
    extra,
    loading,
    title,
    type = "card",
    ...props
  } = params;
  /** 页面容器内容 */
  const PageContent = useMemo(
    () => (
      <div {...props} className="size-full rounded-tl-[36px] bg-secondary p-4">
        <div
          className={clsx(
            className,
            type === "card" &&
              "rounded-3xl border border-background/50 bg-card/80 p-4 backdrop-blur-xl",
            "relative flex size-full flex-col",
          )}
        >
          {(title || extra) && (
            <div className="mb-3 flex items-center justify-between gap-4">
              {title && <PageContainerTitle>{title}</PageContainerTitle>}
              {extra && extra}
            </div>
          )}
          <div className="flex flex-1 overflow-hidden rounded-xl">
            <div
              className="flex flex-1 flex-col"
              style={{ overflowY: "scroll" }}
            >
              {loading ? <FixedSkeleton /> : children}
            </div>
          </div>
        </div>
      </div>
    ),
    [children, className, extra, loading, props, title, type],
  );
  return <div className="size-full bg-card/50">{PageContent}</div>;
};

PageContainer.Title = PageContainerTitle;
