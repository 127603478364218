"use client";
import { Typography } from "@components";
import { Label } from "@shadcn-ui/label";
import clsx from "clsx";
import { FC, PropsWithChildren } from "react";
import { useFormContext } from "react-hook-form";

import { useProFormContext } from "../../../form-context";
import { CommonProFormItemType } from "../../types";
import { ResetIcon } from "./reset-icon";

export type ProFormItemLayoutProps = PropsWithChildren<
  { name?: string } & Omit<CommonProFormItemType, "fieldProps" | "name">
>;

export const ProFormItemLayout: FC<ProFormItemLayoutProps> = (props) => {
  const {
    children,
    clear = false,
    descsript,
    label,
    labelCol,
    layout,
    name,
    wrapperCol,
  } = props;

  const form = useFormContext();
  const layoutContext = useProFormContext();
  const onReset = () => name && form.setValue(name, "");
  const _layout = layout ?? layoutContext.layout;
  const _wrapperSpan = wrapperCol?.span ?? layoutContext?.wrapperCol?.span;
  const _labelSpan = labelCol?.span ?? layoutContext?.labelCol?.span;
  return (
    <div
      className={clsx(
        "flex",
        _layout === "vertical" && "flex-col gap-2",
        _layout === "horizontal" && "flex-row items-start gap-4",
      )}
      style={{
        gridColumn: _wrapperSpan ? `span ${_wrapperSpan}` : undefined,
      }}
    >
      {label && (
        <Typography.Small
          style={{
            flex:
              _layout === "horizontal" && _labelSpan
                ? `${_labelSpan / 24}`
                : undefined,
          }}
        >
          <div
            className={clsx(
              "flex items-center",
              _layout === "vertical" && "justify-start leading-4",
              _layout === "horizontal" && "justify-end leading-9",
            )}
          >
            {props.rules?.required && (
              <span className="mr-1 text-destructive">*</span>
            )}
            <span>{label}</span>
          </div>
        </Typography.Small>
      )}
      <div className="flex size-full flex-1 flex-col gap-1">
        <div className="flex size-full gap-1">
          <div className="flex flex-1 items-center gap-2">
            {props.prefix && <Label>{props.prefix}</Label>}
            {children}
            {props.suffix && <Label>{props.suffix}</Label>}
          </div>
          {clear && <ResetIcon className="flex-none" onClick={onReset} />}
        </div>
        {descsript && <Typography.Muted>{descsript}</Typography.Muted>}
        {name && form.formState.errors?.[name] && (
          <div className="text-xs text-destructive">
            {form.formState.errors[name]?.message?.toString()}
          </div>
        )}
      </div>
    </div>
  );
};
