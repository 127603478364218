import assetDashboard from "./asset-dashboard";
import common from "./common";
import home from "./home";
import navbar from "./navbar";
import setting from "./setting";
import indicators from "./indicators";

export const zh = {
  ...common,
  ...home,
  ...navbar,
  ...assetDashboard,
  ...setting,
  ...indicators,
};
