export default {
  "indicators.title": "Indicators",
  "indicators.bond_market_title": "Bond Market",
  "indicators.commodity_title": "Commodity",
  "indicators.market_index_title": "Market Index",
  "indicators.2_year_bond_title": "2-Year Bond",
  "indicators.10_year_bond_title": "10-Year Bond",
  "indicators.30_year_bond_title": "30-Year Bond",
  "indicators.gold_price_title": "Gold Price",
  "indicators.oil_price_title": "Oil Price",
  "indicators.copper_price_title": "Copper Price",
  "indicators.sp_500_index_title": "S&P 500 Index",
  "indicators.nasdaq_index_title": "NASDAQ Index",
  "indicators.dow_jones_index_title": "Dow Jones Index",
  "indicators.foreign_exchange_title": "Foreign Exchange",
  "indicators.usd_cny_title": "USD/CNY",
  "indicators.eur_usd_title": "EUR/USD",
  "indicators.usd_eur_title": "USD/EUR",
}; 
