export default {
  "indicators.title": "指标",
  "indicators.bond_market_title": "债券市场指标",
  "indicators.commodity_title": "大宗商品指标",
  "indicators.market_index_title": "市场指数",
  "indicators.2_year_bond_title": "2年期美债收益率",
  "indicators.10_year_bond_title": "10年期美债收益率",
  "indicators.30_year_bond_title": "30年期美债收益率",
  "indicators.gold_price_title": "黄金价格",
  "indicators.oil_price_title": "原油价格",
  "indicators.copper_price_title": "铜价指数",
  "indicators.sp_500_index_title": "标普500指数",
  "indicators.nasdaq_index_title": "纳斯达克指数",
  "indicators.dow_jones_index_title": "道琼斯工业指数",
  "indicators.foreign_exchange_title": "外汇",
  "indicators.usd_cny_title": "美元兑人民币",
  "indicators.eur_usd_title": "欧元兑美元",
  "indicators.usd_eur_title": "美元兑欧元",
}; 
