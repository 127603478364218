'use client';

import Image from "next/image";
import React, { FC } from "react";

export const ComingSoon: FC = () => {
  return (
    <div className="relative grid size-full place-items-center">
      <div className="flex max-w-lg flex-col items-center justify-center gap-y-4 text-center">
        <Image
          alt="coming soon"
          className="rounded-xl bg-white/80 p-10"
          src={require("./imgs/todo.svg")}
        />
        <div className="text-xl font-bold">Coming Soon</div>
      </div>
    </div>
  );
};
